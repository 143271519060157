
.footer-container {
    background-color: #333; /* Dark background for the footer */
    color: #f5f5f5;         /* White text color */
    text-align: center;     /* Center the content */
    padding-top: 20px;  
    padding-bottom: 20px;  
    width: 100%;            /* Make the footer take up the full width */
    position: relative;     /* Make it behave naturally at the bottom */
}

.footer-text {
    font-size: 14px;
    margin: 0;               /* Remove default margin */
}

.footer-link {
    color: white;          /* Color for the link */
    font-size: 14px;
    text-decoration: none;   /* Remove underline from the link */
    margin-left: 10px;       /* Add space between the text and link */
}

.footer-link:hover {
    text-decoration: underline;  /* Add underline on hover */
}