.card {
  border: 0;
  border-radius: 0px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 2px 3px rgba(0,0,0,0.03);
  box-shadow: 0 2px 3px rgba(0,0,0,0.03);
  -webkit-transition: .5s;
  transition: .5s;
}

.padding {
  padding: 3rem !important
}

body {
  background-color: #ffffff
}

h5.card-title {
  font-size: 15px;
}

.fw-400 {
  font-weight: 400 !important;
}

.card-title {
  font-family: Roboto,sans-serif;
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 0;
  padding: 15px 20px;
  border-bottom: 1px solid rgba(77,82,89,0.07);
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-control {
  border-color: #ebebeb;
  border-radius: 2px;
  color: #8b95a5;
  padding: 5px 12px;
  font-size: 14px;
  line-height: inherit;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

.card-body>*:last-child {
  margin-bottom: 0;
}


.btn-bold {
  font-family: Roboto,sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
}

.btn-primary:hover {
  background-color: #52d3c7;
  border-color: #52d3c7;
  color: #fff;
}

.btn:hover {
  cursor: pointer;
}

.form-control:focus {
  border-color: #83e0d7;
  color: #4d5259;
  -webkit-box-shadow: 0 0 0 0.1rem rgba(51,202,187,0.15);
  box-shadow: 0 0 0 0.1rem rgba(51,202,187,0.15);
}

.custom-radio {
  cursor: pointer;
}

.custom-control {
  display: -webkit-box;
  display: flex;
  min-width: 18px;
}

.btn .btn-danger{
  margin-left: 5px !important;
}

#dvuserregister .form-control{
  border-color:blue
}

#dvuserreset .form-control{
  border-color:blue
}
