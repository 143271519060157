.m-l-5{
  margin-left: 5px !important;
}

.secondarybutton {
  font-weight: 600; /* Set the font weight to semibold */
  border: 2px solid #1aa179; /* 2px wide, solid, green border */
  padding: 8px 20px; /* Add some padding for better appearance */
  background-color: white; /* Optional: set background color */
  color: #1aa179; /* Optional: set text color */
  border-radius: 5px; /* Optional: round the corners */
  font-size: 16px; /* Optional: set font size */
  cursor: pointer; /* Optional: change cursor to pointer on hover */
  display: block;
  width: 100%; /* Button will take full width of the card */
  text-align: center;
  margin-top: 10px; /* Add some spacing above the button */
}

.primarybutton {
  font-weight: 600; /* Set the font weight to semibold */
  border: 2px solid #1aa179; /* 2px wide, solid, green border */
  padding: 8px 20px; /* Add some padding for better appearance */
  background-color: #1aa179; /* Optional: set background color */
  color: #ffffff; /* Optional: set text color */
  border-radius: 5px; /* Optional: round the corners */
  font-size: 16px; /* Optional: set font size */
  cursor: pointer; /* Optional: change cursor to pointer on hover */
  display: block;
  width: 100%; /* Button will take full width of the card */
  text-align: center;
  margin-top: 10px; /* Add some spacing above the button */
}

.button-container {
  display: flex;
  flex-direction: column; /* Default to vertical */
  align-items: center; /* Center align */
}

.button-container button {
  margin: 10px; /* Add some spacing between buttons */
}

@media (min-width: 768px) { /* Adjust breakpoint as needed */
  .button-container {
      flex-direction: row; /* Change to horizontal on desktop */
  }
  
  .secondarybutton, .primarybutton {
      width: 150px; /* Fixed width for desktop */
  }
}




.card-container {
  display: flex;
  justify-content: space-between; /* Space between cards */
  flex-wrap: wrap; /* Allows wrapping for smaller screens */
  gap: 20px; /* Space between cards */
  padding: 20px;
}

.card-skeleton {
  width: 22%; /* Each card takes up about 22% of the container width */
  background: #f4f4f4;
  padding: 20px;
  border-radius: 10px;
}

.section-skeleton {
  width: 100%; /* Each card takes up about 22% of the container width */
  background: #f4f4f4;
  padding: 20px;
  border-radius: 10px;
}

.skeleton {
  background: #e0e0e0;
  border-radius: 4px;
  margin: 10px 0;
}

.skeleton-image {
  width: 80%;
  height: 250px;
  margin-bottom: 10px;
}

.skeleton-title {
  width: 80%;
  height: 30px;
  margin-bottom: 10px;
}

.skeleton-description {
  width: 100%;
  height: 20px;
  margin-bottom: 6px;
}

.skeleton-dashboardKPI {
  width: 100%;
  height: 110px;
  margin-bottom: 6px;
}

.skeleton-Detail-section1 {
  width: 100%;
  height: 500px;
  margin-bottom: 6px;
}

.skeleton-Detail-section2 {
  width: 100%;
  height: 250px;
  margin-bottom: 6px;
}

.skeleton-title, .skeleton-description .skeleton-pagination .skeleton-dashboardKPI .skeleton-Detail-section1 .skeleton-Detail-section2 {
  animation: loading 1.2s infinite ease-in-out;
}

@keyframes loading {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #cfcfcf;
  }
  100% {
    background-color: #e0e0e0;
  }
}

.card-content {
  width: 22%;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-content h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.card-content p {
  font-size: 1rem;
  color: #555;
}

@media (max-width: 768px) {
  .card-skeleton, .card-content {
    width: 48%; /* Two cards per row on smaller screens */
  }
}

@media (max-width: 480px) {
  .card-skeleton, .card-content {
    width: 100%; /* One card per row on very small screens */
  }
}



/* Ensure consistent margin for each field */
.card-text.field {
  margin-bottom: 8px; /* Adjust this value to control space between fields */
  font-size: 16px;  /* Optional: Adjust font size for better readability */
}

/* Optional: style the link separately if you want different styling */
.profile-link {
  margin-bottom: 8px;  /* Add margin to the link to keep it consistent with other fields */
}
