html,body{
  height:100%
}
  body{
   /* background: url(../img/bodyImage.jpg); */
   /* background: url(../img/login_svg_cover.svg);
  background-size: cover;  */
  background-color:#ffffff;
}  


#dvloginbackground{
  /* height: 100%;
  width: 100%; */
  background-image: url(../img/login_svg_cover.svg);
  background-repeat: no-repeat;
  /* background-color: red; */
  width: 568px;
  height: 568px;
  /* background-size: cover; */
} 

.loginimage {
  image: url(../img/login_svg_cover.svg);
  border-radius: 8px;
  width: 300px;
  height: auto;
}


 #dvloginbackground{
  /* height: 100%;
  width: 100%; */
  background-image: url(../img/login_svg_cover.svg);
  background-repeat: no-repeat;
  /* background-color: red; */
  width: 568px;
  height: 568px;
  /* background-size: cover; */
} 

#dvloginlogo{
  background-image: url(../img/Utthira_logo.svg);
  /* background-repeat: no-repeat; */
  /* background-color: red; */
  width: 50px;
  height: 50px;
}

.logincompanyName{
  color: #1aa179;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bolder;
  text-align: left;
  font-size: clamp(2rem, 5vw, 3rem);  /* Responsive font size */
  white-space: nowrap;                /* Prevents wrapping */

  
}

.logincompanyTagline{
  color: #1aa179;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  font-size: 1rem;  /* Responsive font size */

}

.app-container {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-responsive-image {
  max-width: 20%; /* Makes the image responsive */
  height: auto;       /* Maintain aspect ratio */
  
}

.responsive-image {
  max-width: 100%; /* Makes the image responsive */
  height: auto;       /* Maintain aspect ratio */
  
}

/* #dvloginsvcover{
  background: url(../img/login_svg_cover.svg);
} */
#dvloginsvcover{
  background: url(../img/pexels-viresh-studio-1444442.jpg);
  background-size: cover;
}


/* .form-login{
background: rgba(236,240,241,.35);
border-radius: 10px;
max-width: 450px;
margin: auto;
padding: 20px;
width: 100%;
margin-top: 20%;
} */


.container{
  background: none !important;
  
}



/* #dvloginbackground{
  background: none !important;
} */


.navbar-nav .nav-link {
  color: #212529; /* Default color */
}

.navbar-nav .nav-link.active {
  color: #1aa179; /* Color when the item is active */
  font-weight: 600; /* Optional: make it bold */
  
}

.navbar-nav .nav-link:hover {
  color: #1aa179; /* Color on hover */
}



.btn-transparent {
  border: none;               /* Remove border */
  background-color: transparent;  /* Remove background color */
  color: inherit;              /* Inherit text color from parent element */
  padding: 0.5rem 1rem;        /* Optional: Adjust padding */
  font-size: 1rem;             /* Optional: Adjust font size */
  cursor: pointer;            /* Change cursor to pointer on hover */
}

.btn-transparent:hover {
  color: #1aa179;               /* Change text color on hover (example: white) */
}


